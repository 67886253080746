
  import { defineComponent, ref, onMounted, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';

  export default defineComponent({
    name: 'categories-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('categoriesListing'), [
          translate('clients'),
        ]);

        loading.value = true;
        await store.dispatch(Actions.GET_CATEGORIES);
        loading.value = false;
      });

      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('titleAr'),
          key: 'titleAr',
          sortable: true,
        },
        {
          name: translate('titleEn'),
          key: 'titleEn',
          sortable: true,
        },
        {
          name: translate('titleKu'),
          key: 'titleKu',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const deleteCategory = async (category) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_CATEGORY, category.id);
          await store.dispatch(Actions.GET_CATEGORIES);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editCategory = (id) => {
        router.push({ name: 'category-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_CATEGORY_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_CATEGORIES);
        loading.value = false;
      };

      return {
        tableData: computed(() => store.getters.categoriesList),
        tableHeader,
        deleteCategory,
        translate,
        totalItems: computed(() => store.getters.categoriesListCount),
        pageChanged,
        goTo,
        editCategory,
        loading,
        can,
      };
    },
  });
